import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Install Trailguide`}</h4>
    <p>{`Open `}<a parentName="p" {...{
        "href": "https://trailguide.net"
      }}>{`trailguide.net`}</a>{` in your web browser on your phone.`}</p>
    <br />
    <p>{`On Android, web-apps are integrated like native apps. The second time you
open Trailguide you can click on the "Install" button at the top left
and it will be installed. If there is no "Install" button, just
`}<a parentName="p" {...{
        "href": "https://natomasunified.org/kb/add-website-to-mobile-device-home-screen/"
      }}>{`add it to your home screen`}</a>{`
to install it.`}</p>
    <br />
    <p>{`On IOS, open trailguide.net in the Safari Browser and
`}<a parentName="p" {...{
        "href": "https://natomasunified.org/kb/add-website-to-mobile-device-home-screen/"
      }}>{`add it to your home screen`}</a>{`.
It will then be installed as an app and also work offline.`}</p>
    <br />
    <p>{`Starting Trailguide from the icon on your homescreen it will behave and
look like a native app.`}</p>
    <Image src="features/installation.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`What is a web-app?`}</h4>
    <p>{`A web-app has the functionality of a native app, works offline and has access
to the device hardware.`}</p>
    <br />
    <p>{`But it is easily accessible through the browser, is very small (the size of
Trailguide is just 270kB), fast and efficient.`}</p>
    <br />
    <p>{`Web-apps combine the advantages of a native app and a website.`}</p>
    <br />
    <p>{`A web app cannot be found in the App Store or Play Store.`}</p>
    <Image src="features/startscreen3.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <Link className="float-left" to="/features/oneminute" mdxType="Link">
  ← The app in one minute
    </Link>
    <Link className="float-right" to="/features/map" mdxType="Link">
  Map →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      